import './Conversationbot.scss';
import React, { useState, useEffect, useRef } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { Link, useLocation } from "react-router-dom";
import { fetchBots, fetchContext, executeRating, fetchallbots, API_URL, API_Endpoint } from '../../Services/apiService';
import { func } from 'prop-types';
import { isMobile } from 'mobile-device-detect';
import { executeGRX, executeGRX_pageview, executeGRX_head, copytoclip, readDisc } from '../../Services/util';
import { config } from '../../Services/config';
import ExternalHTML from '../../components/ExternalHTML';
import Bottomsheet from '../../components/Bottomsheet/Bottomsheet';
import Getsvg from '../../components/Getsvg'
// import Meta from '../../components/Meta';

const Conversationbot = (props) => {
    let pagetype = 'chatpage';
    let subscriber = 'TOI_PLUS';
    let subscriberOf = ['TIMES_USER', 'NON_PAID_USER', 'TOI_PLUS', 'TIMES_PRIME']
    const REACT_APP_ROUTE_TO_HOME = process.env.REACT_APP_ROUTE_TO_HOME;
    const [data, setData] = useState([]);
    const { loginconfig, UserDetails, setUserDetails, ssoOpen, SetSsoOpen, uuid, getIsAppLogin, GetAppTicketID } = props
    const [getSessionID, SetSessionID] = useState('');
    const [loading, setLoading] = useState(false);
    const [msgQueue, setMsgQueue] = useState([]);
    const [getUserPermission, setUserPermission] = useState([]);
    const [getLoginStatus, setLoginStatus] = useState(false);
    const [getuserStatus, setuserStatus] = useState('');
    const [GetTicketID, SetTicketID] = useState('');
    const [isFollowups, setFollowups] = useState({});
    // const [getIsAppLogin, setIsAppLogin] = useState(false);
    const appLogingRef = useRef(getIsAppLogin);

    const[componentLoaded, setComponentLoaded] =  useState(false);

    const [Q_index, SetQ_index] = useState(0);
    const inputRef = useRef(null);
    const Ref = useRef(null);
    let followupsdiv = document.getElementsByClassName('followups');
    let params = new URL(document.location).searchParams;
    let comingfrom = params.get("comingfrom") || '';
    let messages = (comingfrom in config.frmapp) ? config.frmapp[comingfrom].messages : config.frmapp.web.messages;
    // App Bridge code Starts
    let SubObjaos = { "type": "deeplink", "value": "toiapp://open-$|$-id=1-$|$-type=planPage-$|$-source=articleShow", "requestReason": "payment" }
    let SubObjiOS = { "type": "deeplink", "value": "toiapp://open-$|$-id=1-$|$-type=planPage-$|$-source=listing-$|$-openPaymentSameAsStory=1", "requestReason": "payment" }
    //let SubObjShare = {"type":"share","value":"sharing with options \n"+window.location.pathname+"?comingfrom="+comingfrom,"requestReason":"share" }
    let subscribe_deepLink_aos = JSON.stringify(SubObjaos)
    let subscribe_deepLink_ios = JSON.stringify(SubObjiOS)
    // let shareChat_deepLink = JSON.stringify(SubObjShare)

    const executeAppBanner = (banner) => {
        if (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad')) {
            document.getElementById(`${banner}-ios`).style.display = 'block';
            document.getElementById(`${banner}-android`).style.display = 'none';
        }
        else {
            document.getElementById(`${banner}-ios`).style.display = 'none';
            document.getElementById(`${banner}-android`).style.display = 'block';
        }
    }

    const executeContext = (tid, enctid) => {
        fetchContext(tid, enctid)
            .then((res) => res.json())
            .then((d) => {
                document.getElementById('inputtext').style.display = "block";
                document.getElementById('sub_btn').style.display = "block";
                SetSessionID(d.session_id)
                setUserPermission(d);

                if (d && d?.context && d?.context?.available_credits_day <= 0) {
                    document.getElementById('errmsgDiv').innerHTML = `<div class='creditMsg'>${messages.credit_day_msg} <button>${messages.subscribe_toi}</button></div>`;
                } else if (subscriberOf.indexOf(d && d?.context && d?.context?.subscriber_of) !== -1) {
                   if (d && d?.context && d?.context?.subscriber_of == 'NON_PAID_USER') {
                        if (d && d?.context?.available_credits_day <= 2) {
                            document.getElementById('statusmsg').innerHTML = `<span class='redcolor'><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>  ${d?.context?.available_credits_day} ${d?.context?.available_credits_day === 10 ? '' : '/10'} ${messages.fee_Q_day} <i class="fa fa-chevron-circle-right"></i></span>`;
                        }
                    }
                    else if ((d && d?.context && d?.context?.subscriber_of) in config?.subscriberType) {
                        document.getElementById('statusmsg').innerHTML = `<span class='greencolor'><i class="fa fa-check-circle"></i>${config.subscriberType[d.context.subscriber_of].unlimited_access_msg}</span>`;
                    }
                }
                else if (d && d?.customCode === 1000004) {
                    if ((comingfrom in config.frmapp)) {
                        executeAppBanner('subscribe')
                    }
                    else {
                        document.getElementById('errmsgDiv').innerHTML = `<div class='creditMsg'>${messages.ta_availability_join} <button>${messages.subscribe_to_continue}</button></div>`
                    }

                }
                else if (d && d?.customCode === 1000003) {
                    document.getElementById('errmsgDiv').innerHTML = `<div class='creditMsg'>${messages.issue_login_session}</div>`
                }
                executeGRX_pageview('website', pagetype, data && data?.name, d && d.context && d.context.subscriber_of == subscriber ? 5 : 0)
                setTimeout(function () {
                    let isbubblemsg = document.getElementById('bubblemsg');
                    let isInputTxt = document.getElementById('inputtext');
                    if (isbubblemsg && isInputTxt) {
                        if (isInputTxt.value.length > 0 && (d && d?.context && d.context?.available_credits_day > 0)) {
                            isbubblemsg.classList.remove('fadeout');
                            isbubblemsg.classList.add('fadein');
                        } else {
                            isbubblemsg.classList.remove('fadein');
                            isbubblemsg.classList.add('fadeout');
                        }
                    }
                }, 2500)
            })
            .catch(err => { console.log(err) })
    }

    // App Bridge code Starts

    const location = useLocation();

    let loginBannerTimeout = null;

    useEffect(() => {
        appLogingRef.current = getIsAppLogin;
        if (!getIsAppLogin) {
            loginBannerTimeout = setTimeout(markLoginBannerVisible, 4000)
        } else if (getIsAppLogin && GetAppTicketID != '') {
            if ((comingfrom in config.frmapp)) {
                if(loginBannerTimeout) {
                    clearTimeout(loginBannerTimeout)
                }
                if ((document.getElementById('login-ios') || document.getElementById('login-android'))) {
                    document.getElementById('login-ios').style.visibility = 'hidden';
                    document.getElementById('login-android').style.visibility = 'hidden';
                }
                executeContext(GetAppTicketID, '')
            }
        }

        if((comingfrom in config.frmapp) && (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad'))){
            window.webkit.messageHandlers.tilAppWebBridge.postMessage('{\"type\":\"forward\", \"value\":\"user moved forward\", \"requestReason\":\"forward\"}')
        }
    }, [props.getIsAppLogin]);

    const markLoginBannerVisible = () => {
        if (!appLogingRef.current) {
            if (document.getElementById('login-ios') || document.getElementById('login-android')) {
                if (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad')) {
                    document.getElementById('login-ios').style.visibility = 'visible';
                    document.getElementById('login-android').style.visibility = 'hidden';
                } else {
                    document.getElementById('login-ios').style.visibility = 'hidden';
                    document.getElementById('login-android').style.visibility = 'visible';
                }
            }
        }
    }

    // App Bridge Code End

    useEffect(() => {
        //inputRef?.current?.focus();
        if (document.getElementById('backbtn')) { document.getElementById('backbtn').style.visibility = "visible" }
        setTimeout(function () {
            try {
                document.getElementById("first_question").classList.add('fade');
            } catch (ex) { }

        }, 1500)

    }, [])

    useEffect(() => {
        const id = window.location.pathname.split("/")[5];
        if (id !== undefined) {
            fetchBots(id)
                .then((res) => res.json())
                .then((d) => {
                    setData(d);
                    let isBotTitle = document.getElementById('botName');
                    let isdisclaim = document.getElementById('disclaim');
                    let issyn = document.getElementById('syn');
                    setTimeout(function () {
                        if (isBotTitle) {
                            isBotTitle.classList.remove('fadeout')
                            isBotTitle.classList.add('fadein')
                        }
                        if (isdisclaim) {
                            isdisclaim.classList.remove('fadeout')
                            isdisclaim.classList.add('fadein')
                        }
                        if (issyn) {
                            issyn.classList.remove('fadeout')
                            issyn.classList.add('fadein')
                        }
                    }, 300)
                    setTimeout(function () {
                        let isInputTxt = document.getElementById('inputtext');
                        let issubBtn = document.getElementById('sub_btn');
                        if (isInputTxt) { isInputTxt.style.display = "block" }
                        if (issubBtn) { issubBtn.style.display = "block" }
                        if (isInputTxt) {
                            isInputTxt.value = d.input_template;
                            //if (isMobile) { document.getElementById('inputtext').style.height = '56px' }
                        }
                        autoResize()

                    }, 2000)
                }).catch(err => { })
        }


        if (!(comingfrom in config.frmapp)) {
            window.jsso.getValidLoggedInUser((res) => {
                if (res.status == "SUCCESS") {
                    let tid = res && res?.data && res?.data?.ticketId;
                    let enctid = res && res?.data && res?.data?.encTicket;
                    executeContext(tid, enctid)
                    setLoginStatus(true)
                }
            })
        }
    }, [])

    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
        height: "200",
    };
    const elementScroll = (chat_id) => {
        try {
            if (chat_id) {
                var access = document.getElementById(chat_id);
                access.scrollIntoView({ behavior: 'smooth', block: 'start' }, true);
            }
        } catch (ex) { }
    }
    const Scrolltobottom = () => {
        const scrollingElement = (document.scrollingElement || document.body);
        const scrollele = scrollingElement.scrollHeight;
        window.scrollTo({
            top: (scrollele),
            behavior: 'smooth'
        })
    }
    const handleUserQuery = (query, type, chat_id) => {
        setTimeout(function () {
            Scrolltobottom();
            //elementScroll(chat_id);
        }, 500)
        let msgObj = { sender: "USER", ts: new Date(), "msg": query, "userInputId": Date.now() }
        if ((getUserPermission && getUserPermission.code === 400) || getUserPermission.code === 401 || getUserPermission.code === 404 || getUserPermission.code === 500) {
            var x = document.getElementById("snackbar");
            x.className = "show";
            x.innerHTML = getUserPermission && getUserPermission?.error
            setTimeout(function () {
                x.className = x.className.replace("show", "");
            }, 60000);
        }
        else {
            setMsgQueue(arr => [...arr, msgObj]);
            try {
                //setTimeout(function () { elementScroll(); }, 500)
                // if (chat_id != undefined) { document.getElementById('followups_' + chat_id).style.display = "none" }
                let followsQ = document.getElementById('followups');
                // let followsQMain = document.getElementById('followupsMain');
                if (followsQ != undefined || followsQ != null) {
                    followsQ.remove();
                    // followsQMain.remove();
                }
                executeQuery(query, type, Date.now());
                //executeGRX('A_likely_ans', query)
                executeGRX(type, 'chatpage', `${data && data?.name}`, `${query}`)
            } catch (err) { }
        }
    }

    const handleUserInput = (type) => {
        let query = document.getElementById("inputtext").value || '';
        if (query.length > 0) {
            handleUserQuery(query, type);
            //setTimeout(function () { elementScroll(); }, 100)
        }
    }

    useEffect(() => {
        if (document.getElementById('inputtext')) { document.getElementById('inputtext').placeholder = isFollowups.value > 0 ? 'Choose one of the suggestions or type your own' : 'Reply'; }
    }, [isFollowups])

    async function fetchWithTimeout(resource, options) {
        const { timeout = 60000 } = options;
        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout);
        const response = await fetch(resource, {
            ...options,
            signal: controller.signal
        });
        clearTimeout(id);
        return response;
    }

    const executeQuery = (query, type, userMsgId) => {
        SetQ_index(Q_index + 1)
        //setTimeout(function () { elementScroll(userMsgId); }, 10)
        setLoading(true);
        document.getElementById('inputtext').placeholder = '';
        document.getElementById('inputtext').value = '';
        let isbubblemsg = document.getElementById('bubblemsg');
        if (isbubblemsg) {
            isbubblemsg.classList.remove('fadein');
            isbubblemsg.classList.add('fadeout')
        }
        //document.getElementById('inputtext').style.height = isMobile ? "65px" : "100px";
        //document.getElementById('inputtext').style.paddingTop = "13px"
        autoResize();
        let url = `${API_URL}/${API_Endpoint}/bot/execute`;
        let uuid = window.location.pathname.split("/")[5];
        let payload = {
            "uuid": uuid,
            "prompt": query
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "session-id": getSessionID },
            body: JSON.stringify(payload)
        };

        const fetchInfo = () => {
            return fetchWithTimeout(url, requestOptions)
                .then((res) => {
                    return Promise.all([res.json(), res.status])
                })
                .then(out => {
                    let status = out[1]
                    //status = 400;
                    let json = out[0]
                    if (status == 201 || status == 200) {
                        return json
                    } else {
                        throw new Error(JSON.stringify(json))
                    }
                })
                .then((d) => {
                    let contextObj = { "context": d && d?.context?.user, 'session_id': d?.session_id }
                    setUserPermission(contextObj)
                    let msgObj = {
                        sender: "SYSTEM",
                        ts: new Date(),
                        "msg": d && d?.response,
                        "follow_ups": d && d?.follow_ups,
                        "chat_id": d && d?.chat_id
                    };
                    setMsgQueue(arr => [...arr, msgObj]);
                    if (d.session_id) { SetSessionID(d.session_id) }
                    else { executeGRX('sessionIDCheck', d.session_id) }
                    setLoading(false);
                    setuserStatus(d.code);
                    setFollowups({ value: d.follow_ups.length, timestamp: Date.now() })
                    setTimeout(function () {
                        elementScroll(msgObj.chat_id);
                    }, 10)
                    setTimeout(function () {
                        try {
                            document.getElementById("followups").classList.add('fade');
                            //elementScroll(msgObj.chat_id);
                        } catch (ex) { }
                    }, 1000)
                    let body = {
                        chat_sessionid: getSessionID,
                        category: (data && data?.category) || '',
                        bot_name: (data && data?.name) || '',
                        //Q: query || '',
                        //A_reply: msgObj.msg || '',
                        A_followup_q: '<currenly merged with A_reply>',
                        prompt_tokens_consumed: (d && d?.meta && d?.meta?.prompt_tokens_consumed) || '',
                        total_tokens_consumed: (d && d?.meta && d?.meta?.total_tokens_consumed) || '',
                        credit_balance_after: (d && d?.meta && d?.meta?.credit_balance_after) || '',
                        credits_used: (d && d?.meta && d?.meta?.credits_used) || '',
                        submit_type: type != '' ? type : '',
                        context: (d && d?.meta && d?.meta?.gpt_prompt_sent) || '',
                        prompt_version: '-99',
                        Q_index: Q_index,
                        gpt_model: (d && d?.meta && d?.meta?.gpt_model) || '',
                        A_likely_ans: (d && d?.follow_ups.length)
                        //A_likely_ans: (d && d?.follow_ups.toString()) || ''
                    }
                    try {
                        let slot_name = `${data && data?.name}`
                        let primeStatus = getUserPermission && getUserPermission?.context && getUserPermission?.context?.subscriber_of == subscriber ? 5 : 0;
                        window.grx('track', 'TOIAssist_QuestionSubmit', { body: JSON.stringify({ ...body }), 'slot_name': slot_name, 'dmpId': '', 'userId': localStorage.getItem("ssoID"), 'primeStatus': primeStatus });
                    } catch (error) { }
                    let available_credits_day = d && d?.context && d?.context?.user && d?.context?.user?.available_credits_day
                    let userStatusmsg = document.getElementById('statusmsg');
                    if (userStatusmsg && d && d?.context && d?.context?.user && d?.context?.user?.subscriber_of === 'NON_PAID_USER') {

                        if (available_credits_day === 10) {
                            userStatusmsg.innerHTML = `<span class='redcolor'><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> ${available_credits_day} ${messages.fee_Q_day}. <i class="fa fa-chevron-circle-right"></i></span>`;
                        }
                        else if (available_credits_day === 0) {
                            userStatusmsg.innerHTML = `<span class='redcolor'><i class="fa fa-exclamation-triangle" aria-hidden="true" aria-hidden="true"></i> ${available_credits_day}/10 ${messages.return_tommorrow} <i class="fa fa-chevron-circle-right"></i></span>`
                            document.getElementById('errmsgDiv').innerHTML = `<div class='creditMsg'>${messages.used_credit}<button>${messages.subscribe_toi} </button></div>`;
                        }
                        else if (available_credits_day <= 10) {
                            if (available_credits_day <= 2) {
                                userStatusmsg.innerHTML = `<span class='redcolor'><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> ${available_credits_day}/${messages.free_Q_today} <i class="fa fa-chevron-circle-right"></i></span>`;
                            }
                        }
                        else {
                            userStatusmsg.innerHTML = `<span class='redcolor'><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> ${available_credits_day} ${messages.question_left}</span>`;
                        }
                    }
                    if (d.code == 400 || d.code == 401 || d.code == 404 || d.code == 500) {
                        if (d.customCode === 1000017) {
                            document.getElementById('errmsgDiv').innerHTML = `<div class='creditMsg'>${messages.used_credit}</div>`;
                        }
                        else if (d.customCode === 1000010) {
                            document.getElementById('errmsgDiv').innerHTML = `<div class='creditMsg'>${messages.used_credit}</div>`;
                        }
                        else if (d.customCode === 1000013) {
                            document.getElementById('errmsgDiv').innerHTML = `<div class='creditMsg'>${messages.something_went_wrong}</div>`;
                        }
                        else {
                            var x = document.getElementById("snackbar");
                            x.className = "show";
                            x.innerText = d.customCode == 1000007 ? 'Your chat session expired. reloading bot...' : d.error;
                            setTimeout(function () {
                                x.className = x.className.replace("show", "");
                                if (d.customCode == 1000007) { window.location.reload(); }
                            }, 6000);
                        }
                        window.grx('track', 'TOIAssist_Error', { body: JSON.stringify({ 'error_code': d && d?.error?.code, 'error_message': d && d?.error?.message }), slot_name: 'execute_api' });
                    }


                }).catch(err => {
                    try {
                        err = JSON.parse(err)
                    } catch (err) { }
                    setLoading(false);
                    document.getElementById("errmsgDiv").classList.add('fadein');
                    document.getElementById("errmsgDiv").classList.remove('fadeout');
                    document.getElementById(userMsgId).style.display = "none";
                    document.getElementById("errmsgDiv").innerHTML = "<div class='warningMsg'><div><img src='https://static.toiimg.com/photo/101484206.cms' height='30' width='30'/></div><div>Oh! Times Assist is in high demand and needs a breather. Try again soon, and if the trouble sticks around, give our support team a shout.</div></div>"
                    setTimeout(function () {
                        document.getElementById("errmsgDiv").classList.remove('fadein');
                        document.getElementById("errmsgDiv").classList.add('fadeout');
                        document.getElementById('inputtext').value = payload.prompt;
                    }, 5000);
                    window.grx('track', 'TOIAssist_Error', { body: JSON.stringify({ 'error_code': '404', 'error_message': 'not Found' }), slot_name: 'execute_api' });
                })
        }

        fetchInfo().then(res => { }).catch(err => { })


    }

    const handlessoClick = () => {
        document.getElementById("ssoLogin").style.display = "block";
        SetSsoOpen(true); window.ssoWidget("init", loginconfig);
    }



    const handleKeyDown = (event) => {
        //if (!isMobile) {
        let query2 = document.getElementById("inputtext").value ?? ''
        let query_reg = query2.replace(/\n/g, '').replace(/\r/g, '')
        if (event.shiftKey && event.key == "Enter") {
            event.preventDefault();
        }
        else if (!event.shiftKey && event.key == "Enter" && query_reg.length) {
            handleUserQuery(query2);
        }
        //}

    }


    const handleKeyfocus = (evt) => {
        try {
            if (isMobile && followupsdiv.length > 0) {
                document.getElementsByClassName('followups')[0].style.display = 'none';
            }
            let plholder = document.getElementById('inputtext')
            if (plholder) { plholder.placeholder = '' }
        } catch (ex) { }
    }
    const handleKeyBlur = (evt) => {
        try {
            if (isMobile && followupsdiv.length > 0) {
                document.getElementsByClassName('followups')[0].style.display = 'block';
            }
        } catch (ex) { }
    }


    const autoResize = () => {
        const textarea = document.getElementById('inputtext');
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    }

    const isAppAuthorized = () => {
        let isAppAuth = (comingfrom in config.frmapp) && getIsAppLogin;
        let hasCredit = (getUserPermission && getUserPermission?.customCode) !== 1000004 && (getUserPermission && getUserPermission?.context?.available_credits_day > 0)
        return (isAppAuth) && hasCredit
    }
    const isWebAuthorized = () => {
        let isWebAuth = getLoginStatus
        let hasCredit = (getUserPermission && getUserPermission?.customCode) !== 1000004 && (getUserPermission && getUserPermission?.context?.available_credits_day > 0)
        return (isWebAuth) && hasCredit
    }
    const launchSubscription = (e) =>{
        if(getUserPermission && getUserPermission?.context && getUserPermission?.context?.subscriber_of!=='NON_PAID_USER'){
            e.preventDefault()
        }
        else{
            if(navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad')){
                window.webkit.messageHandlers.tilAppWebBridge.postMessage(`${subscribe_deepLink_ios}`)
            }
            else{
                window.tilAppWebBridge.postMessage(`${subscribe_deepLink_aos}`)
            }
        }

    }
    const launchSubscriptionWeb = (e) =>{
        if(getUserPermission && getUserPermission?.context && getUserPermission?.context?.subscriber_of!=='NON_PAID_USER'){
            e.preventDefault()
        }
        else{
            readDisc(true, 'subscribe')
        }
    }
    return (
        <div className={(comingfrom in config.frmapp) ? 'covbotmain-app' : 'covbotmain'}>
            {/* <Meta type="chatPage" description={data && data?.description} title={data && data?.name} imageUrl={data && data?.image_url}/> */}
            {(comingfrom in config.frmapp) && <div id="bgoverlay" />}
            <div id="botcontainer" className={(isAppAuthorized() || isWebAuthorized()) ? 'botimage' : 'botimage _disabled'}>
                <div className='crispmsg'>
                    <div className='botTitleconv fadeout' id="botName" bot-id={data && data?.name} bot-cat={data && data?.category} bot-desc={data && data?.description}>{data && data?.name}</div>
                    <div className='botUsage'>
                        <div className='botusageTitle'>
                            <pre>{data && data?.bot_usage && data?.bot_usage?.header}</pre>
                        </div>
                        <div>
                            <ul>
                                {data && data?.bot_usage && data?.bot_usage?.usages.map(val => {
                                    return <li>{val}</li>
                                })
                                }

                            </ul>
                        </div>
                    </div>
                </div>
                {isMobile && <div className='disclaim fadeout' id="disclaim">
                    TimesAssist is powered by AI...<a className='readmore' href="javascript:void(0)" onClick={() => { readDisc(true, 'disc'); executeGRX_head('disclaimer', 'chatpage') }}>Read more</a>
                </div>}
                {!isMobile && <div className='disclaim fadeout' id="disclaim">
                    TimesAssist is powered by AI, so surprises and mistakes are possible. Make sure to check the facts.<a className='readmore' href="javascript:void(0)" onClick={() => { readDisc(true, 'disc'); executeGRX_head('disclaimer', 'chatpage') }}>Read more</a>
                </div>}
                {window.location.pathname.split("/")[5] !== undefined && <div className='fquestion'>
                    <div id="syn" className='syn fadeout'><span>{(data && data?.greeting) || ''}</span></div>
                    <div id="first_question" className='syn'><span>{(data && data?.first_question) || ''}</span></div>
                </div>}


                <div id="sampleinput" className='sampleinput'>{data && data?.sample_inputs && data?.sample_inputs.map(val =>
                    <button className='sampleinputlink'
                        disabled={localStorage.getItem("ssoID") == null ? true : false} onClick={() => handleUserQuery(val.text, 'sample_q_click')}>{val.text}</button>
                )}
                </div>
            </div>
            {/* {!(comingfrom in config.frmapp) && <a style={{ textDecoration: 'none' }} href="https://timesofindia.indiatimes.com/toi-plus/plans?utm_source=timesassist.com&utm_medium=chat-window&utm_campaign=for-subscribers-only" target="_blank"><div id="errmsgDiv" /></a>} */}
            {!(comingfrom in config.frmapp) && <a style={{ textDecoration: 'none' }} onClick={() => readDisc(true, 'subscribe')}><div id="errmsgDiv" /></a>}
            {(comingfrom in config.frmapp) && (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad')) && <div onClick={() => window.webkit.messageHandlers.tilAppWebBridge.postMessage(`${subscribe_deepLink_ios}`)} id="errmsgDiv" />}
            {(comingfrom in config.frmapp) && <div onClick={() => window.tilAppWebBridge.postMessage(`${subscribe_deepLink_aos}`)} id="errmsgDiv" />}

            {!(comingfrom in config.frmapp) && <div id="loginDv2" style={{ display: localStorage.getItem("ssoID") ? 'none' : 'block' }} ><div className='loginMsg'><span>Please login to use Times Assist</span><span><button onClick={handlessoClick}>Login</button></span></div></div>}

            {(comingfrom in config.frmapp) && <div id="login-app">
                <div id="login-ios" style={{ visibility: 'hidden' }}><div class="loginMsg"><span>Please login to use Times Assist</span><span><button onClick={() => window.webkit.messageHandlers.tilAppWebBridge.postMessage(`requestLogin('timesAssistLoginRequest', 'timesAssistLoginRequest')`)}>Login</button></span></div></div>
                <div id="login-android" style={{ visibility: 'hidden' }}><div class="loginMsg"><span>Please login to use Times Assist</span><span><button onClick={() => window.tilAppWebBridge.requestLogin(`timesAssistLoginRequest`, `timesAssistLoginRequest`)}>Login</button></span></div></div>
            </div>}

            {/* <div id="subscribe"><div class='creditMsg'>sharechat Times Assist is available for TOI+ users. Join today! <button onClick={() => window.tilAppWebBridge.postMessage(`${shareChat_deepLink}`)}>Share</button></div></div> */}
            <div id="scrollable" className={msgQueue?.length > 0 ? "flex-container scrollable" : "flex-container"}>
                {getuserStatus !== 400 && <div className="flex-item-right">
                    {msgQueue?.length > 0 && msgQueue.map((msgObj, index) => {
                        return (<div key={msgObj.msg} >
                            {msgObj.sender == 'USER' && <div className="usermsg" id={msgObj.userInputId} style={{ marginLeft: '25%', float: 'right' }}>
                                <div className='userMain'>
                                    <div className='usercontent'>
                                        <pre className='userBlock'>{msgObj.msg}</pre>
                                    </div>
                                </div>
                            </div>}
                            {index === msgQueue.length - 1 && loading && <div style={{ float: 'left' }}> <div className="loader2">
                                <PulseLoader
                                    color={window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#1A1A1A' : '#1A1A1A'}
                                    loading={loading}
                                    cssOverride={override}
                                    size={10}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                    speedMultiplier="0.7"
                                ></PulseLoader>
                            </div></div>}
                            {msgObj.sender == 'SYSTEM' && msgObj.msg != undefined && <div className="systemmsg">
                                <span id={`${msgObj.chat_id}`} />
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <div className='systemcontent'>
                                        <pre className='systemBlock'>{msgObj.msg}</pre>
                                    </div>
                                </div>
                                {msgObj && msgObj?.follow_ups?.length > 0 && msgObj.follow_ups[0] != null ? <div id="followups" className='followups' >{msgObj && msgObj?.follow_ups && msgObj?.follow_ups != null && msgObj?.follow_ups.map(val => <button key={val} onClick={() => handleUserQuery(val, 'followup_q_click', msgObj.chat_id)}>{val}</button>)}</div> : ''}


                            </div>}
                        </div>)
                    })
                    }
                </div>}
            </div>


            {window.location.pathname.split("/")[5] !== undefined && <div id="fixedpos" className='fixedpos'>
                {((getLoginStatus) || (getIsAppLogin)) && <div id="bubblemsg" className='bubblemsg fadeout'><i class="fa fa-info-circle"></i> Change this for personal answers.</div>}
                <div className='inputdiv'>
                    {!(comingfrom in config.frmapp) && <textarea style={{ display: 'none' }}
                        type="text" id="inputtext"
                        ref={inputRef}
                        placeholder={localStorage.getItem("ssoID") === null ? 'Login to continue' : 'Reply'}
                        disabled={(!isWebAuthorized() || loading) ? true : false} onKeyUp={handleKeyDown} onFocus={(e) => handleKeyfocus(e.currentTarget)} onBlur={(e) => handleKeyBlur(e.currentTarget)} onInput={(e) => autoResize()} />
                    }

                    {(comingfrom in config.frmapp) && <textarea style={{ display: 'none' }}
                        type="text" id="inputtext"
                        ref={inputRef}
                        placeholder={getIsAppLogin ? 'Reply' : 'Login to continue'}
                        disabled={(loading || !isAppAuthorized()) ? true : false} onKeyUp={handleKeyDown} onFocus={(e) => handleKeyfocus(e.currentTarget)} onBlur={(e) => handleKeyBlur(e.currentTarget)} />}

                    {!(comingfrom in config.frmapp) && <button id="sub_btn" style={{ display: 'none' }} className={(localStorage.getItem("ssoID") !== null &&
                        !loading && getUserPermission && getUserPermission?.customCode !== 1000004 &&
                        getUserPermission && getUserPermission?.context?.available_credits_day > 0) ? 'bg-enter_1' : 'bg-enter_2'} onClick={() => handleUserInput('chatpage_submit_btn')}
                        disabled={((getUserPermission && getUserPermission?.context && getUserPermission?.context?.available_credits_day > 0) &&
                            localStorage.getItem("ssoID") !== null &&
                            !loading) ? false : true} ><i class="fa fa-arrow-right"></i></button>}

                    {(comingfrom in config.frmapp) && <button id="sub_btn" style={{ display: 'none' }}
                        disabled={loading || (getUserPermission && getUserPermission?.context && getUserPermission?.context?.available_credits_day == 0) || !getIsAppLogin ? true : false}
                        className={loading || (getUserPermission && getUserPermission?.context?.available_credits_day == 0) || !getIsAppLogin ? 'bg-enter_2' : 'bg-enter_1'}
                        onClick={() => handleUserInput('chatpage_submit_btn')}><i class="fa fa-arrow-right"></i></button>}
                </div>
                {(comingfrom in config.frmapp) ?
                    <a style={{ textDecoration: 'none' }} onClick={(e) => launchSubscription(e)}>
                        <span className='statusmsg' id="statusmsg"></span>
                    </a> :
                    <div onClick={(e) => launchSubscriptionWeb(e)}><span className='statusmsg' id="statusmsg"></span></div>
                    // <a class={((getUserPermission && getUserPermission?.context && getUserPermission?.context?.subscriber_of === 'TIMES_USER') || (getUserPermission && getUserPermission?.context && getUserPermission?.context?.subscriber_of === 'TOI_PLUS')) ? 'disabled' : ''} style={{ textDecoration: 'none' }} href='https://timesofindia.indiatimes.com/toi-plus/plans?utm_source=timesassist.com&utm_medium=chat-window&utm_campaign=for-subscribers-only' target="_blank" rel="noopener noreferrer"><span className='statusmsg' id="statusmsg"></span></a>
                }
            </div>}


            <div id="snackbar">Thanks! Your feedback helps us improve our quality of responses.</div>
            <div id="disclaimer">
                <div className='disclose'>
                    <span>Disclaimer:</span>
                    <i className="fa fa-close" onClick={() => readDisc(false, 'disc')}></i>
                </div>
                <div className='disctext'>
                    The responses are AI-generated powered by GPT-3. Please consider the limitations and potential inaccuracies associated with AI-generated responses and use the information provided at your own discretion.<br /><br /> Please take all steps necessary to ascertain that any information is correct, updated and verified as responses may produce inaccurate or out of date information.<br /><br /> Please do not share any personal or sensitive personal information such as name, phone numbers, emails, passwords, financial, payment or medical/health related information. <br /><br />Times Internet Ltd. hereby disclaims responsibility for any consequences or damages arising from the information shared or from the use of the information/response generated.
                </div>

            </div>
            <div id="pv">
                <div className='disclose'>
                    <span>Privacy Policy:</span>
                    <i className="fa fa-close" onClick={() => readDisc(false, 'pv')}></i>
                </div>
                <div className='disctext'>
                    <ExternalHTML url='privacyPolicy' />
                </div>
            </div>
            <div id="tnc">
                <div className='disclose'>
                    <span>Terms of Use and Grievance Redressal Policy :</span>
                    <i className="fa fa-close" onClick={() => readDisc(false, 'tnc')}></i>
                </div>
                <div className='disctext'>
                    <ExternalHTML url='Termsandcond' />
                </div>
            </div>
            <div id="subscribe">
                <div className='disclose-sub'>
                    <i className="fa fa-close" onClick={() => readDisc(false, 'subscribe')}></i>
                </div>
                <div className='disctext-sub'>
                <button onClick={() => window.location.href = 'https://timesofindia.indiatimes.com/toi-plus/plans?utm_source=timesassist.com&utm_medium=chat-window&utm_campaign=for-subscribers-only'}><img src="https://static.toiimg.com/photo/103353563.cms" width="83" height="24"/></button>
                <button onClick={() => window.location.href = 'https://www.timesprime.com/campaigns/p320-new?utm_source=timesassist.com&utm_medium=chat-window&utm_campaign=for-subscribers-only'}><img src="https://static.toiimg.com/photo/103353624.cms" width="130" height="28"/></button>
                </div>
            </div>

            {data && data?.name && <div style={{ marginLeft: '-1%' }}><Bottomsheet page="chatpage" type="copytoclipboard" readDisc={readDisc} copytoclip={copytoclip} botname={data && data?.name} botdesc={data && data?.description} botimg={data && data?.image_url} /></div>}

        </div>

    )
}

export default Conversationbot;