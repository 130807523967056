

import React, { useEffect, useState } from "react";
import RootRouter from "./components/Router";
import Header from './components/Header/Header';
import HeaderApp from "./components/Header/HeaderApp";
import { BrowserRouter as Router } from "react-router-dom";
import { config, GRX_PROJECT_ID } from '../src/Services/config';
import { executeGRX, executeGRX_pageview } from './Services/util';
import './App.scss';


function App(props) {
  let params = new URL(document.location).searchParams;
  let comingfrom = params.get("comingfrom") || '';
  let messages = (comingfrom in config.frmapp) ? config.frmapp[comingfrom].messages : config.frmapp.web.messages
  const [UserDetails, setUserDetails] = useState([]);
  const [ssoOpen, SetSsoOpen] = useState(false);
  const [getIsAppLogin, setIsAppLogin] = useState(false);
  const [GetAppTicketID, SetAppTicketID] = useState('');

  const invokeAfterIsAppReadySuccess = () => {
    if (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad')) {
      if (typeof window.webkit.messageHandlers.tilAppWebBridge != 'undefined') {
        let loginCommand = `checkLoggedInUser('timesAssistLoginCheck', 'timesAssistLoginCheck')`;
        window.webkit.messageHandlers.tilAppWebBridge.postMessage(loginCommand)
      }
    }
    else {
      if (typeof window.tilAppWebBridge != 'undefined') {
        window.tilAppWebBridge.checkLoggedInUser('timesAssistLoginCheck', 'timesAssistLoginCheck')
      }
    }
  }

  // Init the GRX modules
  if (!(comingfrom in config.frmapp)) {
    window.grx('init', GRX_PROJECT_ID);
  }

  window.initiateAppToWebConnection = (response) => {
    console.log('APP GID', response && response?.grxID)
    invokeAfterIsAppReadySuccess();
    window.grx('init', GRX_PROJECT_ID, { 'anonymous_id': response && response?.grxID });
  }

  window.isAppReady = () => {
    invokeAfterIsAppReadySuccess();
    window.grx('init', GRX_PROJECT_ID);
  }

  window.onLoginSuccess = (reqId, userInfo, extraInfo) => {
    setIsAppLogin(true);
    userInfo = JSON.parse(userInfo);
    if (userInfo.ssoid && userInfo.ticketid) {
      SetAppTicketID(userInfo && userInfo?.ticketid);
    }
  }
  window.onLoginFailed = (reqId, userInfo, extraInfo) => {
    setIsAppLogin(false);
    if (extraInfo == "timesAssistLoginCheck") {
    } else if (extraInfo == 'timesAssistLoginRequest') { }
  }

  window.messageFromApp = (response) => {
    window.location.reload();
  }

  const loginconfig = {
    channelName: 'timesassist',
    element: "ssoLogin",
    resendOtpTimer: 2,
    channelLogo: "https://static.toiimg.com/photo/102798555.cms",
    displayChannel: "Times Assist",


    //ru:'/',

    socialLoginRu: `${process.env.REACT_APP_JSSO_URL}/sso/identity/login?channel=` + 'timesassist',
    nonSocialLogin: {
      loginVia: ["email", "mobile"],
      loginWith: ["otp", "Password"]
    },
    socialLogin: [
      // {
      //   type:"Facebook",
      //   label:"Sign in with Facebook",
      //   logoUrl:"https://jssocdn.indiatimes.com/crosswalk/30/widget/img/fb.png",
      //   clientId:"898459706886386"
      // },
      {
        type: "Google",
        logoUrl: "",
        label: "Sign in with Google",
        clientId: '103703403489-b4t4lt8mr05brqpcdrmsu0di54cmjv4f.apps.googleusercontent.com'
      }
    ],

    signInCallback: function (response) {
      try {
        window.jsso.getValidLoggedInUser((res) => {
          localStorage.setItem('ticketId', res && res?.data && res?.data?.ticketId);
          localStorage.setItem('encTicket', res && res?.data && res?.data?.encTicket);
          window.jsso.getUserDetails((res) => {
            if (res && res?.code === 200) {
              localStorage.setItem('ssoID', res && res?.data && res?.data?.ssoid);
              window.grx('track', 'TOIAssist_Click', { body: JSON.stringify({ slot_name: 'Login', click_val: res && res?.data && res?.data?.loginId }) });
              //setUserDetails(res);
              document.getElementById('ssoLogin').style.display = "none"
              window.location.reload();
            }

          });
        })
        document.getElementById("loginDv").style.display = "none"
        document.getElementById("logoutDv").style.display = "block"
      } catch (error) { }
    },
    closeCallBack: function () {
      try {
        document.getElementById("ssoLogin").style.display = "none";
      } catch (error) { }
    },

    signupForm: {
      defaultFirstName: "Guest",
      signUpFields: {
        "Email": {
          placeholder: "Enter Email",
          required: true
        },
        "FullName": {
          placeholder: "Enter Full Name",
          required: true
        },
        "MobileNumber": {
          placeholder: "Enter Mobile Number",
          required: false
        }

      },
      // recaptcha:{
      // 	required:true
      // },
      signupVia: ["Password"],
      // MandatoryVerifyVia:["email","mobile"]
      MandatoryVerifyVia: ["emailormobile"],
    },
    termsConditionLink: '${termsurl}',
    privacyPolicyLink: '${policyurl}',
    defaultSelected: true,
    email_password: true,
    email_otp: true,
    mobile_password: true,
    mobile_otp: true,
    closeIcon: true,
    loginOrder: ["nonsocial", "social"],
    // theme:{
    //   "background": "#135394",
    // },
  };

  return (
    <>
      <Router>
        {(comingfrom in config.frmapp) ? <HeaderApp loginconfig={loginconfig} UserDetails={UserDetails} setUserDetails={setUserDetails} ssoOpen={ssoOpen} SetSsoOpen={SetSsoOpen} />
          : <Header loginconfig={loginconfig} UserDetails={UserDetails} setUserDetails={setUserDetails} ssoOpen={ssoOpen} SetSsoOpen={SetSsoOpen} />
        }


        <RootRouter loginconfig={loginconfig} UserDetails={UserDetails} setUserDetails={setUserDetails} ssoOpen={ssoOpen} SetSsoOpen={SetSsoOpen} getIsAppLogin={getIsAppLogin} GetAppTicketID={GetAppTicketID} />
      </Router>

    </>
  );
}

export default App;

