import './Faqgenerator.css';
import React, { useState, useEffect, useSearchParams } from "react";
import FadeLoader from "react-spinners/FadeLoader";
const Faqgenerator = () =>{
    const [data, setData] = useState([]);
    const [results, setResults] = useState([]);
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#000000");
    
    useEffect(()=>{
        const url = `${process.env.REACT_APP_URL}/api/timesassist/`+window.location.pathname.split("/")[3];
        const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
            const fetchInfo = () => {
    return fetch(url,requestOptions)
      .then((res) => res.json())
      .then((d) => {
            setData(d)
            document.getElementById('inputtext').value = d.sample_input
            })  
        }
        fetchInfo()
       
      },[])
    
    const override: CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
        height:"200",
    };
    
    const executeQuery= () => {
        setLoading(true)
        
        let url = `${process.env.REACT_APP_URL}/api/botapps/execute`
        let query = document.getElementById("inputtext").value
        let uuid = window.location.pathname.split("/")[3]
        
        let payload = {
            "uuid": uuid,
            "prompt": query
        }
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };
        
        const fetchInfo = () => {
            return fetch(url,requestOptions)
                    .then((res) => res.json())
                    .then((d) => {
                        document.getElementById('outputtext').value = d.response
                        setLoading(false)
                    })  
            }
        
        fetchInfo()
    }
    
    return ( 
        <div className="genbot">
            <div className='botimage'>
                <img src={data.image_url} width="50" height="50" alt=""/>
                <h2>{data && data.name}</h2>
                <span className='syn'>{data && data.description}</span>
            </div>
            <div className="flex-container">
                <div className="flex-item-left">
                    <textarea id="inputtext" value={data && data.name}></textarea>
                </div>
                <div className="flex-item-center">{!loading && <button onClick={executeQuery}>Run</button>}<div className="loader">
            <FadeLoader
                color={color}
                loading={loading}
                cssOverride={override}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div></div>
                <div className="flex-item-right"><textarea id="outputtext" value="Results will appear here..."></textarea></div>
            </div>
        
        </div>
    )
}

export default Faqgenerator;