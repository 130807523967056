import React, { useState, useEffect } from "react";

export default function Getsvg(props) {
    const {height,width} = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21 23" fill="none">
            <path d="M17.0625 14.9141C15.7659 14.9141 14.6139 15.561 13.896 16.5569L7.7154 13.3138C7.81909 12.9521 7.875 12.5695 7.875 12.1738C7.875 11.6379 7.77267 11.1261 7.58739 10.6576L14.065 6.66142C14.7878 7.53245 15.8633 8.08594 17.0625 8.08594C19.2336 8.08594 21 6.27226 21 4.04297C21 1.81368 19.2336 0 17.0625 0C14.8914 0 13.125 1.81368 13.125 4.04297C13.125 4.55822 13.2195 5.05115 13.3914 5.50473L6.89762 9.51086C6.1754 8.66552 5.11617 8.13086 3.9375 8.13086C1.76636 8.13086 0 9.94454 0 12.1738C0 14.4031 1.76636 16.2168 3.9375 16.2168C5.25534 16.2168 6.4239 15.5485 7.13899 14.5248L13.3016 17.7585C13.1869 18.1373 13.125 18.5399 13.125 18.957C13.125 21.1863 14.8914 23 17.0625 23C19.2336 23 21 21.1863 21 18.957C21 16.7277 19.2336 14.9141 17.0625 14.9141ZM17.0625 1.34766C18.5099 1.34766 19.6875 2.55677 19.6875 4.04297C19.6875 5.52916 18.5099 6.73828 17.0625 6.73828C15.6151 6.73828 14.4375 5.52916 14.4375 4.04297C14.4375 2.55677 15.6151 1.34766 17.0625 1.34766ZM3.9375 14.8691C2.49007 14.8691 1.3125 13.66 1.3125 12.1738C1.3125 10.6876 2.49007 9.47852 3.9375 9.47852C5.38492 9.47852 6.5625 10.6876 6.5625 12.1738C6.5625 13.66 5.38492 14.8691 3.9375 14.8691ZM17.0625 21.6523C15.6151 21.6523 14.4375 20.4432 14.4375 18.957C14.4375 17.4708 15.6151 16.2617 17.0625 16.2617C18.5099 16.2617 19.6875 17.4708 19.6875 18.957C19.6875 20.4432 18.5099 21.6523 17.0625 21.6523Z" fill="black" />
        </svg>
    )
}


