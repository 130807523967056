import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Index from '../containers/Index/Index';
import Faqgenerator from '../containers/Faqgenerator/Faqgenerator';
import Conversationbot from '../containers/Conversationbot/Conversationbot';
import Header from './Header/Header';
let homepage = process.env.REACT_APP_HOMEPAGE


function Router(props) {
  return (
      
    <Switch>
      <Route path={`${homepage}/faq/:id`} component={Faqgenerator} />
      <Route path={`${homepage}/conv/:id`} >
        <Conversationbot {...props}/>
      </Route>
      <Route path={`${homepage}/cat/:id`} >
        <Conversationbot {...props}/>
      </Route>
      <Route path={`${homepage}/cat/:id/conv/:id`}>
        <Conversationbot {...props}/>
      </Route>
      <Route exact path={`${homepage}`} component={Index} />
      <Route exact path="/" component={Index} />
     
    </Switch>
  );
}
export default Router;