import React, { useState } from 'react';
import './Header.scss';
import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { executeGRX_head,sharethruApp } from '../../Services/util';
import { config } from '../../Services/config';
import Getsvg from '../Getsvg'
const HeaderApp = (props) => {
    const ref = useRef(null);
    const REACT_APP_ROUTE_TO_HOME = process.env.REACT_APP_ROUTE_TO_HOME;
    let params = new URL(document.location).searchParams;
    let comingfrom = params.get("comingfrom") || '';
    
    
    return (
        <div style={{ height: '50px' }}>
            <nav className='stickynav' style={{ paddingTop: '15px' }}>
                <div className='main-nav'>
                    <div className='logoapp' ref={ref}>
                        <div>
                            <Link to={`${REACT_APP_ROUTE_TO_HOME}?comingfrom=${comingfrom}`}>
                                <img src="https://static.toiimg.com/photo/102912191.cms" height="16" width="150" />
                            </Link>
                            <br />
                            <span>Experiment</span>
                        </div>
                    </div>
                    {(navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad')) ?
                        <div id="shareicon-app" style={{ float: 'left', visibility: 'visible' }} onClick={() => { executeGRX_head('ta_share_btn', 'chatpage'); sharethruApp('home', 'iOS') }}>
                            <Getsvg width="21" height="23" type="talogo" />
                        </div> :
                        <div id="shareicon-app" style={{ float: 'left', visibility: 'visible' }} onClick={() => { executeGRX_head('ta_share_btn', 'chatpage'); sharethruApp('home', 'AOS') }}>
                            <Getsvg width="21" height="23" />
                        </div>
                    }
                    
                    

                </div>
            </nav>
        </div>
    )
}

export default HeaderApp;